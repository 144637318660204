.referal-text-heading {
  font-family: Manrope !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #050C19 !important;
  margin: 1.5rem 0 !important;

}
.referal-text-base {
  font-family: Manrope !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #373D47 !important;
}

.pad-request-list {
  padding: 1rem 2rem;
  list-style-position: inside;
}
.agreement-list {
  list-style-type: upper-roman;
  padding: 1rem 2rem;
  list-style-position: inside;
  .list-item {
    padding: 1rem 0.7rem !important;
  }
}
.agreement-list-alpha {
  list-style-type: lower-alpha;
  padding: 1rem 2rem;
  list-style-position: inside;
  li {
    margin-bottom: 1rem !important;
  }
}
.list-style-alpha {
  list-style-type: lower-alpha;
  list-style-position: inside;
  padding: 0rem 2rem;
}

.agreement-list-heading {
  display: inline;
  font-weight: bold !important;
}
.alpha-list-item {
  display: inline !important;
  padding-left: 0.5rem !important;
}
.referral-image {
  width: 100%;
}
.refunds-list-item {
  margin-bottom: 1rem !important;
}