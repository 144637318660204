.discount {
  text-decoration: line-through;
  text-decoration-color: red;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  margin-right: 0.2rem;
  position: relative;
  width: 30px;
}
.discounted {
  position: absolute;
  top: -10px;
  left: 0px;
  color: #ff0000 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}
